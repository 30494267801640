import React from "react";

import SEO from "../components/SEO";
import HeroSection from "../components/partials/beyond-lp/HeroSimpler";
import HowToSection from "../components/partials/beyond-lp/HowToSimpler";

const IndexPage = () => {
  return (
    <>
      <SEO
        title="Memories with ClassDojo Plus"
        description="ClassDojo brings your school together, wherever you are, free"
        twitter={{
          card: "summary_large_image",
          site: "@classdojo",
          creator: "@classdojo",
          title: "Home Points on ClassDojo",
          description: "ClassDojo brings your school together, wherever you are, free",
        }}
      />
      <HeroSection />
      <HowToSection />
    </>
  );
};
export default IndexPage;
